
// Normalize
@import '~normalize-scss'
@include normalize()

// Jquery
@import "vendor/jquery_ui"

// Font Awesome
// We have to re-write the font path so that we can load the fonts correctly as sass doesn't do url writing.
// See here for more info: https://github.com/webpack-contrib/sass-loader#problems-with-url
$fa-font-path: "~font-awesome/fonts"
@import "~font-awesome/scss/font-awesome.scss"

// Sass transitional styles
// These are vendor styles that are included in gems which have been statically copied accross while we go through
// and convert them into their associated npm packages

@import "./vendor/compass_bourbon_neat.css"

@import "./vendor/intlTelInput.css"

