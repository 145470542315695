/**
  Imported from previous "bourbon/sass/neat" helpers that were included in the rails asset pipeline before
  we moved over to webpack
 **/

   /* line 4, /usr/local/bundle/gems/neat-1.9.1/app/assets/stylesheets/grid/_box-sizing.scss */
html {
  box-sizing: border-box;
}

/* line 9, /usr/local/bundle/gems/neat-1.9.1/app/assets/stylesheets/grid/_box-sizing.scss */
*, *::after, *::before {
  box-sizing: inherit;
}
